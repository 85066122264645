// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-03-03-2022-index-js": () => import("./../src/pages/03-03-2022/index.js" /* webpackChunkName: "component---src-pages-03-03-2022-index-js" */),
  "component---src-pages-ikkefrisk-index-js": () => import("./../src/pages/ikkefrisk/index.js" /* webpackChunkName: "component---src-pages-ikkefrisk-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rulett-index-js": () => import("./../src/pages/rulett/index.js" /* webpackChunkName: "component---src-pages-rulett-index-js" */)
}

